<template>
  <div class="HelpFaqView">
    <div class="o-container-gray">
      <md-card class="md-layout-item">

        <md-card-header>
          <SectionTitle :titleContent="messages.title"/>
        </md-card-header>

        <md-card-content>

          <div class="o-container-subsection">
            <SectionSubtitle :titleContent="messages.faq1.subtitle"/>
            <TextBlockDefault :textContent="messages.faq1.content1"/>
          </div>

          <div class="o-container-subsection">
            <SectionSubtitle :titleContent="messages.faq2.subtitle"/>
            <TextBlockDefault :textContent="messages.faq2.content1"/>
          </div>

          <div class="o-container-subsection">
            <SectionSubtitle :titleContent="messages.faq3.subtitle"/>
            <TextBlockDefault :textContent="messages.faq3.content1"/>
            <TextBlockDefault :textContent="messages.faq3.content2"/>
            <a :href="'//' + links.external.rotldPartners"
                class="help-navigation-link"
                target="_blank">
              https://www.rotld.ro/registrar-list
            </a>
          </div>

          <div class="o-container-subsection">
            <SectionSubtitle :titleContent="messages.faq4.subtitle"/>
            <TextBlockDefault :textContent="messages.faq4.content1"/>
          </div>

          <div class="o-container-subsection">
            <SectionSubtitle :titleContent="messages.faq5.subtitle"/>
            <TextBlockDefault :textContent="messages.faq5.content1"/>
          </div>

          <div class="o-container-subsection">
            <SectionSubtitle :titleContent="messages.faq6.subtitle"/>
            <TextBlockDefault :textContent="messages.faq6.content1"/>
          </div>

          <div class="o-container-subsection">
            <SectionSubtitle :titleContent="messages.faq7.subtitle"/>
            <TextBlockDefault :textContent="messages.faq7.content1"/>
          </div>

          <div class="o-container-subsection">
            <SectionSubtitle :titleContent="messages.faq8.subtitle"/>
            <TextBlockDefault :textContent="messages.faq8.content1"/>
            <md-list>
              <md-list-item>{{ messages.faq8.content2 }}</md-list-item>
              <md-list-item>{{ messages.faq8.content3 }}</md-list-item>
            </md-list>
          </div>

          <div class="o-container-subsection">
            <SectionSubtitle :titleContent="messages.faq9.subtitle"/>
            <TextBlockDefault :textContent="messages.faq9.content1"/>
          </div>

          <div class="o-container-subsection">
            <SectionSubtitle :titleContent="messages.faq10.subtitle"/>
            <TextBlockDefault :textContent="messages.faq10.content1"/>
          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import SectionTitle from '@/components/util/SectionTitle';
import SectionSubtitle from '@/components/util/SectionSubtitle';
import TextBlockDefault from '@/components/util/TextBlockDefault';

export default {
  name: 'HelpFaqView',
  components: {
    SectionTitle,
    SectionSubtitle,
    TextBlockDefault
  },
  data() {
    return {
      links: {
        external: {
          rotldPartners: 'https://www.rotld.ro/registrar-list'
        }
      },
      messages: {
        title: this.$t('helpView.subtitle1'),
        faq1: {
          subtitle: this.$t('helpView.faq.question1'),
          content1: this.$t('helpView.faq.answer1')
        },
        faq2: {
          subtitle: this.$t('helpView.faq.question2'),
          content1: this.$t('helpView.faq.answer2')
        },
        faq3: {
          subtitle: this.$t('helpView.faq.question3'),
          content1: this.$t('helpView.faq.answer3_1'),
          content2: this.$t('helpView.faq.answer3_2')
        },
        faq4: {
          subtitle: this.$t('helpView.faq.question4'),
          content1: this.$t('helpView.faq.answer4')
        },
        faq5: {
          subtitle: this.$t('helpView.faq.question5'),
          content1: this.$t('helpView.faq.answer5')
        },
        faq6: {
          subtitle: this.$t('helpView.faq.question6'),
          content1: this.$t('helpView.faq.answer6')
        },
        faq7: {
          subtitle: this.$t('helpView.faq.question7'),
          content1: this.$t('helpView.faq.answer7')
        },
        faq8: {
          subtitle: this.$t('helpView.faq.question8'),
          content1: this.$t('helpView.faq.answer8_1'),
          content2: this.$t('helpView.faq.answer8_2'),
          content3: this.$t('helpView.faq.answer8_3')
        },
        faq9: {
          subtitle: this.$t('helpView.faq.question9'),
          content1: this.$t('helpView.faq.answer9')
        },
        faq10: {
          subtitle: this.$t('helpView.faq.question10'),
          content1: this.$t('helpView.faq.answer10')
        },
      }
    };
  },
};
</script>

<style lang="scss" scoped>
.HelpFaqView {

  .md-list {
    padding-top: 0;
    margin: 0 .8rem 1.6rem .8rem;
  }

  .o-container-subsection {
    text-align: left;
  }

  .SectionSubtitle {
    margin-left: .8rem;
    padding: .8rem;
  }

  .TextBlockDefault {
    margin-left: .8rem;
    padding: .8rem;
  }

  .help-navigation-link {
    margin-left: .8rem;
    padding: .8rem;
    text-align: left;
  }
}
</style>
