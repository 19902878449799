import { render, staticRenderFns } from "./HelpFaqView.vue?vue&type=template&id=098a6bbe&scoped=true"
import script from "./HelpFaqView.vue?vue&type=script&lang=js"
export * from "./HelpFaqView.vue?vue&type=script&lang=js"
import style0 from "./HelpFaqView.vue?vue&type=style&index=0&id=098a6bbe&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "098a6bbe",
  null
  
)

export default component.exports